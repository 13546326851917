<template>
    <div class="main_wrp">
        <NavBottom />
        <div class="tabtop">
            <span :class="acindx==1?'on':''" @click="showList(1)">未用</span>
            <span :class="acindx==2?'on':''" @click="showList(2)">已用</span>
            <span :class="acindx==3?'on':''" @click="showList(3)">失效</span>
            <!-- <span :class="acindx==4?'on':''" @click="showList(4)">待评价</span>-->
        </div>
        <div class="card_list">
            <!-- <UserPad /> -->
            <template v-if="plist.length == 0">
                <div class="no-data">抱歉，当前服务器未查询到相关数据！！！</div>
            </template>
            <template v-else>
                <div class="quan clear"  v-for="(item,index) in plist" :key="index">
                    <div class="quanLeft">
                        <p class="money">¥ {{item.Coupon_Money}}</p>
                        <div class="info">
                            <p class="tit">{{item.Coupon_Content}}</p>
                            <p class="conts">无满额限制,立享折扣</p>
                            <p class="dates">有效期: {{item.Coupon_StartTime.substring(0,11)}} - {{item.Coupon_EndTime.substring(0,11)}}</p>
                            <a href="javascript:void(0);" class="get-qrcode" v-if="item.Coupon_QrCodeUrl != '' && item.Coupon_QrCodeUrl != null"  @click="sharePaper(item.Coupon_QrCodeUrl)">立刻生成我的专属朋友圈分享海报 &gt;&gt;</a>
                        </div>
                    </div>
                    <div class="quanRight">
                        <p class="ft">{{item.Coupon_Name}}</p>
                    </div>
                </div>

                <a href="javascript:void(0);" class="more-data" @click="loadmore(acindx)">加载更多</a>
            </template>
        </div>
        <div class="remark" v-if="remarkShow">
            <span class="closeBtn" @click="closeRemark">关闭</span>
            <h5>申请退款原因</h5>
            <textarea v-model="remark" cols="30" rows="10"></textarea>
            <button @click="sureRemark">确定</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            status: 0,
            acindx: 0,
            pageindex: 1,
            gindx: 0,
            types: [],
            plist: [],
            member: "",
            remark: "",
            currentId: 0,
            remarkShow: false,
        };
    },
    created(opt) {
        this.status = this.acindx = this.$route.params.status;
        console.log("status:", this.$route.params.status);
        this.showList(this.$route.params.status);
    },

    methods: {
        sharePaper(codeurl){
            localStorage.setItem("coupon_qrcode", codeurl);
            this.$router.push({
                path:"/shareCouponQrcode"
            })
            
        },
        showList: function (id) {
            let that = this;
            this.plist = [];
            this.acindx = id;
            this.pageindex = 1;
            this.wpost({
                url: "/Ajax/WordWelfare/getcouponlist",
                data: {
                    status: Number(id - 1),
                    pagesize: 20,
                    pageindex: that.pageindex,
                },
                succ: function (data) {
                    // console.log("result:",data)
                    that.plist = data.list;
                    that.member = data.member;
                },
            });
        },
        loadmore: function (id) {
            let that = this;
            // console.log('loadmore data')
            this.wpost({
                url: "/Ajax/WordWelfare/getcouponlist",
                data: {
                    status: Number(id - 1),
                    pagesize: 20,
                    pageindex: (that.pageindex += 1),
                },
                succ: function (data) {
                    if (data.list.length > 0) {
                        that.plist = that.plist.concat(data.list);
                    } else {
                        that.pageindex -= 1;
                        alert("当前无更多数据...");
                    }
                },
            });
            // console.log("pageindex:", that.pageindex)
        },
    },
};
</script>

<style scoped>
.get-qrcode{
    border: 2px solid #fff;
    padding: 3px 8px;
    color: #e91f2f;
    border-radius: 2px;
    font-size: 14px;
    margin: 6px 0;
    background: #fff;
    display: inline-block;
    font-weight: bold;
    box-shadow: 0 0 6px #ccc;
    font-family: cursive;
}
.quan {
    position: relative;
    margin: 26px 6px;
    padding: 8px;
    box-sizing: border-box;
    background-color: #f97a0a;
    color: #fff;
    background-image: radial-gradient(#fff 35%, #f97a0a 35%);
    background-size: 16px 16px;
    background-position: -8px 0;
    background-repeat: repeat-y;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.quanLeft {
    background-image: radial-gradient(#fff 15%, #f97a0a 15.1%,  #f97a0a 35%);
    background-size: 11px 11px;
    background-repeat: repeat-y;
    background-position: 100% 0;
    padding-right: 12px;
    box-sizing: border-box;
    width: 80%;
}
.money {
    font-size: 26px;
    border-bottom: 2px solid #fff;
    margin: 7px 0;
    line-height: 33px;
}
.info{
    font-size: 12px;
    line-height: 1.5;
}
.ft{
    writing-mode: vertical-rl;
    letter-spacing: 4px;
    font-size: 13px;
}
.closeBtn {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 12px;
    color: #e91f2f;
}
.remark {
    width: 97%;
    position: fixed;
    top: 12vh;
    left: 1.5%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px #ccc;
}
.remark h5 {
    text-indent: 6px;
    margin: 12px 0;
}
.remark textarea {
    width: 96%;
    margin: 0 auto;
    display: block;
    resize: none;
}
.remark button {
    height: 33px;
    width: 70px;
    background: #ff5a00;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 6px;
    margin: 10px auto;
    display: block;
}
.wtop {
    position: relative;
}

.wtop image {
    width: 100%;
}

.wtopIn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.wtips {
    position: absolute;
    top: 6px;
    right: 12px;
    color: #fff;
    font-size: 24rpx;
}

.statusText {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid #f0ad4e;
    box-shadow: 0 0 3px 1px #f0ad4e;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    right: 6px;
    bottom: 12px;
}

.card_main .statusText text {
    margin: 0;
    color: #f97a0a;
    font-size: 11px;
}

.card_main {
    position: relative;
}

.userInfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: #fff;
}

.wimg {
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.wimg image {
    width: 70px;
    height: 70px;
}

.wtext {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.wtext text {
    font-size: 25rpx;
}

.warr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    color: #fff;
}

.main_wrp {
    height: 100vh;
    position: relative;
}

.top_head {
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f97a0a;
}

.btnswrp {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    border: 1px solid #f0f0f0;
}

.btnswrp a {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding: 2px 6px;
    margin: 6px 0;
    color: #888;
    border: 1px solid #888;
    border-radius: 20px;
    font-size: 13px;
}
.btnswrp button {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding: 2px 6px;
    margin: 6px 0;
    color: #999;
    border: 1px solid #999;
    border-radius: 20px;
    font-size: 13px;
}
.card_wrp button {
    margin: 10px 5px;
}

.card_list {
    padding-top: 41px;
    padding-bottom: 70px;
    margin-bottom: 70px;
    box-sizing: border-box;
}

.tabtop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
}

.tabtop span {
    display: block;
    width: 25%;
    color: #3f536e;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
    border: none;
    background-color: none;
}

.tabtop span.on {
    color: #f97a0a;
}

.tabtop span:active {
    background-color: #ececec;
}

.card_intros {
    font-size: 32rpx;
    line-height: 1.6;
    padding: 10px;
    font-weight: bold;
}

.card_wrp {
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.card_lit {
    padding: 2px 10px;
}

.card_tit {
    color: #333;
    padding: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    border-bottom: 1px solid #f0f0f0;
    overflow: hidden;
    font-size: 15px;
    margin: 6px 0;
}

.card_main {
    font-size: 14px;
    color: #e91f2f;
}

.card_main text {
    margin-right: 6px;
    font-size: 13px;
    color: #333;
}

.nav_county {
    width: 150upx;
    text-align: center;
    border-right: 1px solid #dedede;
    box-sizing: border-box;
}

.nav_county text {
    display: block;
    padding: 12px 0;
    font-size: 14px;
    background-color: #ececec;
    color: #888;
    border: 1px solid #dedede;
    border-top: none;
    border-right: none;
}

.nav_county text.ac {
    color: #f97a0a;
    background: #fff;
}
</style>
